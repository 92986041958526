<template>
  <div>
    <div class="box">
      <div>
        <select-patient class="columns is-multiline"
                        only-search
                        @selectOrder="setOrder">
        </select-patient>

        <div class="columns">
          <div class="column is-one-quarter">
            <b-field label="Año">
              <b-select expanded v-model="selectedYear">
                <option :value="option.key" v-for="(option, index) in years" :key="index">{{option.value}}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-one-quarter">
            <search-order v-model="search"
                          ref="search_order"
                          @setData="onSetData"
                          :year="selectedYear"
                          :downloadResultsLink="$alfalab.canDownloadResultLink($auth.user())"
                          type="order"
            ></search-order>
          </div>
        </div>
      </div>
    </div>

    <div class="box" v-if="hasOrder">
      <div class="columns is-multiline">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 10px; align-items: center;">
          <b-tooltip
              v-if="$alfalab.enabledUploadMaquila($auth.user())"
              label="Carga de PDF Maquila">
            <b-button
                type="is-primary"
                @click="showPDFMaquila = true"
                icon-left="laptop-medical"></b-button>
          </b-tooltip>
        </div>
        <div class="column is-12">
          <p>Paciente: {{order.patient.full_name}}</p>
          <div  class="cancel_message">
          <b-message type="is-danger" v-if="order.is_cancel">
            Orden Cancelada
          </b-message>
          </div>
        </div>
        <div class="column">
          <b-field label="Email">
            <b-input v-model="email"></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="" class="">
            <b-button class="is-primary" @click="onSendMail" expanded>Enviar Todos</b-button>
          </b-field>
        </div>
        <div class="column">
          <b-field label="">
            <b-button class="" expanded @click="onPrint">Imprimir Seleccionados</b-button>
          </b-field>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-3-desktop is-2-widescreen" v-for="item in results">
          <div class="card">
            <div class="card-image">

            </div>
            <div class="card-content">
              <figure class="image">
                <b-checkbox v-model="selected[item.type]"
                >
                  <img :src="item.img" :alt="item.title">
                </b-checkbox>
              </figure>
              <h3 class="subtitle" v-if="!$alfalab.canDownloadResultLink($auth.user())">{{ item.title }}</h3>
              <h3 class="subtitle" v-else>Descargar: <a :href="item.url" target="_blank" type="octet/stream">{{ item.title }}</a></h3>
              <button v-if="$alfalab.canGenerateDocument($auth.user(), item)" @click="() => runAction(item)">Volver a generar documento</button>
              <button v-if="item.can_delete && $alfalab.enabledUploadMaquila($auth.user())" class="button  is-primary" @click="() => deleteAction(item)">Eliminar</button>
            </div>
          </div>
        </div>
      </div>
      <template v-if="$alfalab.isAdmin($auth.user()) && order.order_new && awsDocuments.length">
        <div>
          <hr>
          <h2>Documentos de resultados almacenados en AWS</h2>
          <div class="columns is-multiline">
            <div v-for="(item, index) in awsDocuments" :key="index">
              <a :href="item.backendUrl"
                 target="_blank"
                 class="column is-3" >{{item.basename}}</a>
            </div>
          </div>
        </div>
      </template>
    </div>
    <b-modal v-model="showPDFMaquila">
      <maquila-modal :order="order" @reload="onReload"/>
    </b-modal>
  </div>
</template>

<script>
import SelectPatient from "../../components/patient/SelectPatient";
import SearchOrder from "../../components/order/SearchOrder";
import MaquilaModal from "../../components/maquila/MaquilaModal.vue";
export default {
  name: "IndexResult",
  components: {MaquilaModal, SearchOrder, SelectPatient},
  data() {
    return {
      apiUrl: 'orders',
      order: {},
      data: [],
      hasOrder: false,
      url: process.env.VUE_APP_URL,
      results: [],
      search: '',
      email: '',
      selectedYear: (new Date().getFullYear() % 10) + "" ,
      selected: {},
      awsDocuments: {},
      showPDFMaquila: false
    }
  },
  computed: {
    year() {
      return this.$store.state.main.year || '0';
    },
    years(){
      const current = new Date().getFullYear();
      const years = [];
      years.push({key: (current % 10) + "", value: current});
      years.push({key: ((current-1) % 10) + "", value: current-1});
      //years.push({key: ((current-2) % 10) + "", value: current-2});
      //years.push({key: ((current-3) % 10) + "", value: current-3});
     // years.push({key: ((current-2) % 10) + "", value: current-2});
      return years;
    }
  },
  methods: {
    onSelectPatient(data) {
      this.getAll({
        patient: data.code
      });
    },
    onSetData(data) {
      //console.log(data);
      this.awsDocuments = [];
      this.hasOrder = true;
      this.order = data;
      this.email = this.order.patient.email;
      this.results = data.results || [];
      this.selected = {}
      this.getAWSDocuments();
    },
    getUrl(type) {

    },
    getAWSDocuments() {
      this.$http.get(`/orders-aws/results/${this.order.order_new}`).then(({data}) => {
        this.awsDocuments = data;
      })
    },
    async setOrder(data) {
      this.selectedYear = String(data.order_new).substr(0,1)
      this.search = String(data.no);
      this.hasOrder = false;
      this.$loader.show();
      setTimeout(async () => {
        await this.$refs.search_order.searchOrder();
        this.$store.dispatch('setShowModalSelectPatient', false);
        this.$loader.hide();
        this.selected = {}
      }, 500);
    },
    onSendMail(){
      if(this.email){
        this.$loader.show();
        this.$http.post(`/order-results/${this.order.order_new}/email`,
            {
              email:this.email
            }
        ).then(({data}) => {
          this.$toast.success(data.message);
        }).catch(errors => {
          this.$alfalab.showError(errors);
        }).finally(() => {
          this.$loader.hide();
        });
      }else{
        this.$toast.error('Se debe especificar un email');
      }

    },
    onPrint(){
      this.$loader.show();
      this.$http.post(`/order-results/${this.order.order_new}/print`, {
        selected: this.selected,
        printer: (Number.parseInt(localStorage.getItem('printerManager_printer')) || 1)
      }).then(({data}) => {
        this.$toast.success(data.message);
      }).catch(errors => {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.$loader.hide();
      });
    },
    runAction (item) {
      this.$loader.show();
      this.$http.get(item.action).then(() => {
        this.$toast.success('Acción realizada con éxito');
      }).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
      })
    },
    onReload(){
      this.$refs.search_order.onSearch();
    },
    deleteAction(item){
      console.log('item', item);
      this.$buefy.dialog.confirm({
        title: 'Borrar documento de Maquila',
        message: '¿Estás seguro de que deseas borrar este documento de Maquila?',
        confirmText: 'Si, borrar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$loader.show();
          this.$http.delete(`/order/${this.order.id}/${item.result_id}/maquila`).then(({data}) => {
            this.$buefy.dialog.alert({
              title: 'Acción realizada con éxito',
              message: 'Elemento eliminado',
              confirmText: 'Aceptar'
            })
          }).catch(errors => {
            this.$alfalab.showError(errors);
          }).finally(() => {
            this.$refs.search_order.onSearch();
            this.$loader.hide();
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
